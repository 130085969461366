import React from 'react';

export default () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6 11.6667C2.87038 11.6667 0.333328 9.12965 0.333328 6.00004C0.333328 2.87043 2.87038 0.333374 6 0.333374C9.12961 0.333374 11.6667 2.87043 11.6667 6.00004C11.6667 7.32462 11.2122 8.54305 10.4507 9.50792L13.4714 12.5286C13.7317 12.789 13.7317 13.2111 13.4714 13.4714C13.2111 13.7318 12.7889 13.7318 12.5286 13.4714L9.50787 10.4507C8.54301 11.2122 7.32457 11.6667 6 11.6667ZM9.1065 9.0212C9.09118 9.034 9.07631 9.04759 9.06192 9.06197C9.04754 9.07635 9.03395 9.09123 9.02116 9.10655C8.24061 9.86579 7.17491 10.3334 6 10.3334C3.60676 10.3334 1.66666 8.39328 1.66666 6.00004C1.66666 3.60681 3.60676 1.66671 6 1.66671C8.39323 1.66671 10.3333 3.60681 10.3333 6.00004C10.3333 7.17495 9.86574 8.24065 9.1065 9.0212Z" fill="white"/>
  </svg>
);





